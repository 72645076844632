import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { PublicLayoutComponent } from '../../modules/layouts';
import {
  AuthGuard,
  LandingComponent,
  OnboardingComponent,
  OnboardingGuard,
} from '../../modules/shared';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../../modules/auth/auth.module').then(mod => mod.AuthModule),
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('../../modules/user/user.module').then(mod => mod.UserModule),
        canActivate: [AuthGuard, OnboardingGuard],
        data: { noAdmin: false },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
