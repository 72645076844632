import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  CheckNetworkService,
  CheckVersionService,
  LocaleService,
  UserService,
} from '../../modules/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private checkNetworkService: CheckNetworkService,
    private checkVersionService: CheckVersionService,
    private localeService: LocaleService,
  ) {
    this.localeService.changeLocale();
  }

  ngOnInit() {
    this.checkVersionService.checkVersion();

    this.checkNetworkService.checkNetworkStatus();

    this.userService.populate();
  }

  ngOnDestroy() {
    this.checkNetworkService.unsubscribeNetworkStatus();
  }
}
